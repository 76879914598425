import * as XLSX from 'xlsx';
import {formatDate} from '@angular/common';
import {CommonPdfGenerator} from '../../services/exportPDF/common-pdf-generator';
import moment from 'moment';

export class ExcelGenerator extends CommonPdfGenerator {
	generateExcel(
		rfiDetails,
		status,
		materialCosts,
		commercialCosts,
		isToolingWholeOption,
		toolingTotal,
		totCurvRFI,
		totPrevRFI,
		rfiHistoryList,
		buyerSupplier,
		selectedType,
		isLumpSumSelected,
		lsIncludeFInValue
	) {
		const versionData = rfiDetails.rfiId;
		const documentDefinition = this.getPdfContent(
			rfiDetails,
			status,
			materialCosts,
			commercialCosts,
			isToolingWholeOption,
			toolingTotal,
			totCurvRFI,
			totPrevRFI,
			rfiHistoryList,
			buyerSupplier,
			selectedType,
			isLumpSumSelected,
			lsIncludeFInValue
		);
		// Create a new workbook
		const wb = XLSX.utils.book_new();

		// Convert the data to a worksheet
		const ws = XLSX.utils.aoa_to_sheet(documentDefinition);

		// Append the worksheet to the workbook
		XLSX.utils.book_append_sheet(wb, ws, 'RFI_Information_Data');

		// Write the workbook to a file
		XLSX.writeFile(wb, `${versionData}.xlsx`);
	}

	rfiInformation(rfiRequest, buyerSupplier, selectedType, data) {
		data.push([`${selectedType} Information`, '', '', '', '', '']);

		data.push([
			'',
			`${selectedType} # : `,
			this.getValidValueForPdf(rfiRequest.rfiId),
			'',
			`${selectedType} Created Date : `,
			moment(rfiRequest.rfiModifiedDate).format('MMMM-D-yy'),
			''
		]);
		data.push([
			'',
			'Lead Program : ',
			this.getValidValueForPdf(rfiRequest.leadPrg),
			'',
			'PSA # : ',
			this.getValidValueForPdf(rfiRequest.psa),
			''
		]);
		data.push([
			'',
			'Buyer CDSID : ',
			this.getValidValueForPdf(rfiRequest.buyerCode),
			'',
			'D&R CDSID : ',
			this.getValidValueForPdf(rfiRequest.drCDSID),
			''
		]);
		data.push([
			'',
			'Supplier Email : ',

			this.getValidValueForPdf(rfiRequest.supplier),
			'',
			'Supplier Engineer Email : ',
			this.getValidValueForPdf(rfiRequest.secondSupplier),
			''
		]);
		data.push([
			'',
			'Material Group/Commodity Code : ',
			this.getValidValueForPdf(rfiRequest.purCommCode),
			'',
			'Purchasing Group/Buyer Code : ',
			this.getValidValueForPdf(rfiRequest.purGroup),
			''
		]);
		data.push([
			'',
			'Supplier GSDB Code : ',
			this.getValidValueForPdf(rfiRequest.siteCode),
			'',
			'MP&L Plant Code : ',
			this.getValidValueForPdf(rfiRequest.plantCode),
			''
		]);
		data.push([
			'',
			'Modular Catalogue Name : ',
			this.getValidValueForPdf(rfiRequest.moduleName),
			'',

			'Modular Catalogue Type : ',
			this.getValidValueForPdf(rfiRequest.moduleType),
			''
		]);
		data.push([
			'',
			'Part Number/Material Number : ',
			this.getValidValueForPdf(rfiRequest.partNumber),
			'',
			'Part Name : ',
			this.getValidValueForPdf(rfiRequest.partName),
			''
		]);
		data.push([
			'',
			'CMF / DCR # : ',
			this.getValidValueForPdf(rfiRequest.cmfDCR),
			'',
			'CMF/DCR Title : ',
			this.getValidValueForPdf(rfiRequest.cmfDCRTitle),
			''
		]);
		data.push([
			'',
			'Design Change Description : ',
			this.getValidValueForPdf(rfiRequest.changeDesc),
			'',
			'Post FDJ:',
			this.getValidValueForPdf(rfiRequest.postfdjF),
			''
		]);
		data.push([
			'',
			'Private RFI:',
			this.getValidValueForPdf(rfiRequest.privateRFI),
			'',
			'',
			'',
			''
		]);

		data.push([]);
		data.push([]);
		data.push([]);

		if (buyerSupplier === 'buyer') {
			data.push(['Internal Cost Details ', '', '', '', '', '']);
			data.push([
				'',
				'Initial Piece Price Target : ',
				this.getValidValueForPdfDecimal(rfiRequest.partCostTarget),
				'Currency : ',
				this.getValidValueForPdf(rfiRequest.partCostTargetCur),
				''
			]);
			data.push([
				'',
				'Piece Price Status : ',
				this.getValidValueForPdfDecimal(rfiRequest.pdMatCostEstimate),
				'Currency : ',
				this.getValidValueForPdf(rfiRequest.pdMatCostEstimateCur),
				''
			]);
			data.push([
				'',
				'Piece Price Should Cost : ',
				this.getValidValueForPdfDecimal(rfiRequest.e3pMatCostEstimate),
				'Currency : ',
				this.getValidValueForPdf(rfiRequest.e3pMatCostEstimateCur),
				''
			]);
			data.push([
				'',
				'Initial Production Tooling Target : ',
				this.getValidValueForPdfDecimal(rfiRequest.toolingCostTarget),
				'Currency: ',
				this.getValidValueForPdf(rfiRequest.toolingCostTargetCur),
				''
			]);
			data.push([
				'',
				'Production Tooling Status : ',
				this.getValidValueForPdfDecimal(rfiRequest.pdToolingEstimate),
				'Currency : ',
				this.getValidValueForPdf(rfiRequest.pdToolingEstimateCur),
				''
			]);
			data.push([
				'',
				'Tooling Should Cost : ',
				this.getValidValueForPdfDecimal(rfiRequest.e3pToolingEstimate),
				'Currency : ',
				this.getValidValueForPdf(rfiRequest.e3pToolingEstimateCur),
				''
			]);
		}
	}

	buildPiecePriceList(materialCosts, data) {
		data.push([
			'',
			'Cost Breakdown',
			'Previous RFI/RFQ Cost (EUR)',
			'RFI/RFQ Cost (EUR)',
			'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)'
		]);
		if (materialCosts !== undefined && materialCosts != null) {
			materialCosts.forEach((materialCostDetail) => {
				if (!materialCostDetail.isTotal) {
					data.push([
						'',
						materialCostDetail.costBucket,
						this.get4Decimal(materialCostDetail.prevRFI),
						this.get4Decimal(materialCostDetail.curRFI),
						this.getRFIDiff(
							materialCostDetail.curRFI,
							materialCostDetail.prevRFI
						)
					]);
				} else {
					data.push([
						'',
						materialCostDetail.costBucket,
						this.get4Decimal(materialCostDetail.prevRFI),
						this.get4Decimal(materialCostDetail.curRFI),
						this.getRFIDiff(
							materialCostDetail.curRFI,
							materialCostDetail.prevRFI
						)
					]);
				}
			});
		}

		return data;
	}

	buildCommercialAdjustment(
		commercialCosts,
		edt,
		data,
		isLumpSumSelected,
		lsIncludeFInValue
	) {
		if (edt) {
			data.push([
				'',
				'Cost Breakdown',
				'Previous RFI/RFQ Lump Sum ED&T',
				'RFI/RFQ Cost (EUR)',
				'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)'
			]);
		} else {
			data.push([
				'',
				'Cost Breakdown',
				'Previous RFI/RFQ Cost (EUR)',
				'RFI/RFQ Cost (EUR)',
				'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)'
			]);
		}

		if (commercialCosts !== undefined && commercialCosts != null) {
			commercialCosts.forEach((materialCostDetail) => {
				if (edt) {
					if (
						materialCostDetail.costBucket ===
						'ED&T paid in Piece Price'
					) {
						data.push([
							'',
							materialCostDetail.costBucket ==
							'ED&T paid in Piece Price'
								? isLumpSumSelected == undefined ||
									isLumpSumSelected == false
									? 'ED&T paid in Piece Price Value Includes Financing - '
									: ' ED&T paid in Piece Price \n Value Includes Financing - ' +
										lsIncludeFInValue
								: materialCostDetail.costBucket,
							this.get4Decimal(materialCostDetail.prevRFI),

							this.get4Decimal(materialCostDetail.curRFI),
							this.getRFIDiff(
								materialCostDetail.curRFI,
								materialCostDetail.prevRFI
							)
						]);
					}
				} else {
					if (!materialCostDetail.isTotal) {
						data.push([
							'',
							materialCostDetail.costBucket ==
							'ED&T paid in Piece Price'
								? isLumpSumSelected == undefined ||
									isLumpSumSelected == true
									? 'ED&T paid in Piece Price Value Includes Financing - '
									: ' ED&T paid in Piece Price \n Value Includes Financing - ' +
										lsIncludeFInValue
								: materialCostDetail.costBucket,
							this.get4Decimal(materialCostDetail.prevRFI),
							this.get4Decimal(materialCostDetail.curRFI),
							this.getRFIDiff(
								materialCostDetail.curRFI,
								materialCostDetail.prevRFI
							)
						]);
					} else {
						data.push([
							'',
							materialCostDetail.costBucket,
							this.get4Decimal(materialCostDetail.prevRFI),
							this.get4Decimal(materialCostDetail.curRFI),
							this.getRFIDiff(
								materialCostDetail.curRFI,
								materialCostDetail.prevRFI
							)
						]);
					}
				}
			});
		}

		return data;
	}

	buildHistory(rfiHistoryList, data) {
		data.push([
			'',
			'Updated By',
			'Piece Price Subtotal',
			'Piece Price Subtotal Delta',
			'Toling Price Subtotal',
			'Toling Price Subtotal Delta',
			'Commercial Subtotal',
			'Commercial Subtotal Delta',
			'Lump Sum ED&T Subtotal',
			'Lump Sum ED&T Subtotal Delta',
			'Date & Time'
		]);

		rfiHistoryList.forEach((rfiHistory) => {
			data.push([
				'',
				this.getValidValueForPdf(rfiHistory.createdBy),
				this.getValidValueForPdfDecimal(rfiHistory.piecePrice),
				this.getValidValueForPdfDecimal(rfiHistory.piecePriceDelta),
				this.getValidValueForPdfDecimal(rfiHistory.toolingPrice),
				this.getValidValueForPdfDecimal(rfiHistory.toolingPriceDelta),
				this.getValidValueForPdfDecimal(rfiHistory.commPrice),
				this.getValidValueForPdfDecimal(rfiHistory.commPriceDelta),
				this.getValidValueForPdfDecimal(rfiHistory.lumpSumPrice),
				this.getValidValueForPdfDecimal(rfiHistory.lumSumPriceDelta),
				this.getValidValueForPdf(rfiHistory.createDate)
			]);
		});

		return data;
	}

	getValidValueForPdf(val) {
		return val || '';
	}

	getValidValueForPdfDecimal(val) {
		return val || '0.0000';
	}

	buildProductonTooling(
		rfiRequest,
		isToolingWholeOption,
		toolingTotal,
		data
	) {
		data.push([
			'',
			'',
			'Previous RFI/RFQ Production Tooling (EUR)',
			'Supplier’s New Total Tooling (EUR)',
			'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)'
		]);

		if (isToolingWholeOption !== 'true') {
			data.push([
				'',
				'Production Tooling',
				this.getValidValueForPdfDecimal(rfiRequest.rfiPrevCost),
				this.getRFIAdd(rfiRequest.rfiPrevCost, toolingTotal),
				this.getRFIDelta(rfiRequest, isToolingWholeOption, toolingTotal)
			]);
		} else {
			data.push([
				'',
				'Production Tooling',
				this.getValidValueForPdfDecimal(rfiRequest.rfiPrevCost),
				this.getValidValueForPdfDecimal(rfiRequest.rfiCost),
				this.getRFIDelta(rfiRequest, isToolingWholeOption, toolingTotal)
			]);
		}

		return data;
	}

	buildTotalCost(totPrevRFI, totCurvRFI, data) {
		data.push([
			'',
			'Total Cost (Piece Price + Commercial Adjustments)',
			this.get4Decimal(totPrevRFI),
			this.get4Decimal(totCurvRFI),
			this.getRFIDiff(totCurvRFI, totPrevRFI)
		]);

		return data;
	}

	buildComments(rfiRequest, data, buyerSupplier) {
		data.push([
			'',
			'Supplier Comments (Optional)',
			this.getValidValueForPdf(rfiRequest.rfiComments)
		]);
		data.push([
			'',
			'Buyer Comments (Optional)',
			this.getValidValueForPdf(rfiRequest.buyerComments)
		]);
		data.push([
			'',
			'PD Comments (Optional)',
			this.getValidValueForPdf(rfiRequest.pdComments)
		]);
		if (buyerSupplier === 'buyer') {
			data.push([
				'',
				'Internal Comments (Optional)',
				this.getValidValueForPdf(rfiRequest.internalComment)
			]);
		}

		return data;
	}

	get4Decimal(prevRFI) {
		return (Math.sign(prevRFI) * Math.abs(prevRFI)).toFixed(5);
	}

	getRFIAdd(prevRFI, totaTooling) {
		const add: number = Number(totaTooling) + Number(prevRFI);
		const result = add.toFixed(0);
		return result;
	}

	getRFIDiff(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		const result =
			(Math.sign(diff) === 1 ? '-' : Math.sign(diff) === -1 ? '+' : '') +
			Math.abs(diff).toFixed(5);

		return result;
	}

	getRFIDelta(rfiRequest, isToolingWholeOption, toolingTotal) {
		let diff = 0;
		if (isToolingWholeOption !== 'true') {
			diff = toolingTotal;
		} else {
			diff = rfiRequest.rfiCost - rfiRequest.rfiPrevCost;
		}

		//return diff.toFixed(4);

		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			Math.abs(diff).toFixed(0)
		);
	}

	getPdfContent(
		rfiDetails,
		status,
		materialCosts,
		commercialCosts,
		isToolingWholeOption,
		toolingTotal,
		totCurvRFI,
		totPrevRFI,
		rfiHistoryList,
		buyerSupplier,
		selectedType,
		isLumpSumSelected,
		lsIncludeFInValue
	) {
		const data = [];

		this.rfiInformation(rfiDetails, buyerSupplier, selectedType, data);
		data.push([]);
		data.push([]);

		//if(status != 'INITIAL RESPONSE REQUIRED'){
		data.push(['Piece Price']);
		this.buildPiecePriceList(materialCosts, data);
		data.push([]);
		data.push([]);

		data.push(['Commercial Adjustment']);
		this.buildCommercialAdjustment(
			commercialCosts,
			false,
			data,
			isLumpSumSelected,
			lsIncludeFInValue
		);
		data.push([]);
		data.push([]);
		data.push(['Total Costs']);
		this.buildTotalCost(totPrevRFI, totCurvRFI, data);
		data.push([]);
		data.push([]);

		data.push(['Lump Sum ED&T']);
		this.buildCommercialAdjustment(
			commercialCosts,
			true,
			data,
			isLumpSumSelected,
			lsIncludeFInValue
		);
		data.push([]);
		data.push([]);

		data.push(['Production Tooling']);
		this.buildProductonTooling(
			rfiDetails,
			isToolingWholeOption,
			toolingTotal,
			data
		);
		data.push([]);
		data.push([]);

		data.push(['Comments']);
		this.buildComments(rfiDetails, data, buyerSupplier);
		data.push([]);
		data.push([]);

		data.push(['History']);
		this.buildHistory(rfiHistoryList, data);

		return data;
	}
}
